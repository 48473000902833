.NrlinkWidgetsCard .ant-card-body, .NrlinkWidgetsCard .ant-card-meta-detail {
        height: 100%;
 }
 .NrlinkWidgetsCard .ant-card-meta-detail {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
 }

 .NrlinkWidgetsCard .ant-card-meta-detail .ant-card-meta-title {
    padding-bottom: 10px;
    margin-bottom: 0;
 }